import React, {useEffect} from "react"
import LayoutProvider from "../../../src/components/Layout"
import AtuComponent from '../../components/DashboardComponents/Atu';
import { navigate } from 'gatsby';

const DashboardPage = ({ location }) => {

  useEffect(() => {
    const logueado = localStorage.getItem("logueado")
    const requiere_actualizar = localStorage.getItem("requiere_actualizar")

    if (logueado === false || logueado === null || logueado === undefined) {
        navigate("/")
    } else if (requiere_actualizar === true || requiere_actualizar === "true") {
        navigate("/inicio/editar-perfil/")
    }
  }, [])

  return (
    <>
      <LayoutProvider title="Atención de Usuarios" location={location}>
        <div className="contenedor">
          <AtuComponent/>
        </div>
      </LayoutProvider>
    </>
  )
}

export default DashboardPage
