import React, { useEffect } from "react"
import { ConsultaAtuF } from "../../state/actions/Dashboard/AtuActions"
import { useSelector, useDispatch } from "react-redux"
import NoInfoComponent from '../../Utils/NoInfo';
import AtuTableComponent from './AtuComponents/AtuTable';

const AtuComponent = () => {
  const ATU = useSelector(state => state.Atu.Atu)
  const dispatch = useDispatch()

  useEffect(() => {
    if (ATU.length === 0) {
      const ConsultaAtu = () => dispatch(ConsultaAtuF())
      ConsultaAtu()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {ATU.length === 0 ? 
        <NoInfoComponent/>
      :
        <>
          <AtuTableComponent 
            ATU={ATU}
          />
        </>
      }
    </>
  )
}

export default AtuComponent